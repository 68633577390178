import React from "react";
import DentalChair from "./images/about/chair.jpeg";
import CAAK from "./images/insurance/caak.jpeg";
import CIC from "./images/insurance/cic.jpeg";
import FirstAssurance from "./images/insurance/FirstAssurance.png";
import Kengen from "./images/insurance/kengen.jpeg";
import Madison from "./images/insurance/madison.jpeg";
import NHIF from "./images/insurance/nhif.jpeg";
import OurTeam from "./OurTeam";
import Services from "./Services";

export default function LandingPage() {
  return (
    <>
      <div className="container mt-5 pt-5 mb-3">
        <div className="row ">
          <div className="col-md-5 mb-3">
            <img
              src={DentalChair}
              alt="Yaya Dental Clinic"
              className="img-fluid h-100 w-100"
              style={{ borderRadius: "10px" }}
            />
          </div>
          <div className="col-md-7 fw-bold lh-base">
            <h4 className="text-dark lh-lg">
              Welcome to our dental practice, where we prioritize your oral
              health and create beautiful smiles. With a team of skilled
              dentists and friendly staff, we provide top-quality dental care in
              a comfortable and welcoming environment. From routine check-ups
              and cleanings to advanced treatments and cosmetic dentistry, we
              offer a comprehensive range of services to meet your dental needs.
              Our commitment to patient satisfaction and personalized care
              ensures that each visit is a positive and stress-free experience.
            </h4>
          </div>
        </div>
      </div>

      <hr />

      <Services />

      <hr />
      {/* Insurance */}

      <div className="container mt-5">
        <h1 className="text-center fw-bold ">Medical Cover Insurance</h1>
        <h4 className="mb-3 text-center">
          Our dental practice offers comprehensive insurance coverage for your
          peace of mind. From routine check-ups and cleanings to complex
          treatments, our insurance plans have you covered. Enjoy the benefits
          of hassle-free claims, affordable premiums, and access to a wide
          network of experienced dentists. Take care of your oral health without
          breaking the bank. Explore our insurance options today!
        </h4>
        <div class="row row-cols-1 row-cols-md-2 g-4">
          <div class="col mb-3">
            <div class="card">
              <img
                src={CAAK}
                class="card-img-top h-100"
                alt="CAAK"
                style={{ maxHeight: "400px" }}
              />
            </div>
          </div>
          <div class="col mb-3">
            <div class="card">
              <img
                src={CIC}
                class="card-img-top h-100"
                alt="CIC"
                style={{ maxHeight: "400px" }}
              />
            </div>
          </div>
          <div class="col mb-3">
            <div class="card">
              <img
                src={FirstAssurance}
                class="card-img-top"
                alt="FirstAssurance"
                style={{ maxHeight: "400px" }}
              />
            </div>
          </div>
          <div class="col mb-3">
            <div class="card">
              <img
                src={Kengen}
                class="card-img-top h-100"
                alt="Kengen"
                style={{ maxHeight: "400px" }}
              />
            </div>
          </div>

          <div class="col mb-3">
            <div class="card">
              <img
                src={Madison}
                class="card-img-top"
                alt="Madison"
                style={{ maxHeight: "300px" }}
              />
            </div>
          </div>
          <div class="col mb-3">
            <div class="card">
              <img
                src={NHIF}
                class="card-img-top"
                alt="NHIF"
                style={{ maxHeight: "300px" }}
              />
            </div>
          </div>
        </div>
      </div>

      <hr />

      <OurTeam />
    </>
  );
}
