import React from "react";
import Diagnosis from "./images/services/diagnosis.jpeg";
import Implants from "./images/services/implants.jpeg";
import Orthodontics from "./images/services/orthodontics.jpeg";
import PediatricDentistry from "./images/services/pediatricdentistry.jpeg";
import Radiographs from "./images/services/radiographs.jpeg";
import RootCanal from "./images/services/RootCanal.png";
import TeethCleaning from "./images/services/teethcleaning.jpeg";
import TeethFilling from "./images/services/TeethFilling.jpeg";
import TeethWhitening from "./images/services/teethwhitening.jpeg";
import ToothExtraction from "./images/services/toothextraction.jpeg";

export default function Services() {
  return (
    <div className="container mt-5 pt-5 mb-3">
      <h1 className="text-center fw-bold mb-4">Our Services</h1>
      <h4 className="text-center mb-4">
        Experience exceptional dental care at our clinic. Trust us for gentle
        care, personalized attention, and a smile that shines.
      </h4>
      <div class="row row-cols-1 row-cols-md-2 g-4">
        <div class="col">
          <div class="card">
            <img
              src={TeethWhitening}
              class="card-img-top h-100"
              alt="TeethWhitening"
            />
            <div class="card-body">
              <h5 class="card-title text-center">Teeth Whitening</h5>
              <div class="d-flex justify-content-center">
                <button
                  class="btn btn-primary"
                  type="button"
                  className="btn btn-primary"
                  data-mdb-toggle="modal"
                  data-mdb-target="#TeethWhitening"
                >
                  Learn More
                </button>
              </div>

              <div
                className="modal fade"
                id="TeethWhitening"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="TeethWhitening">
                        Teeth Whitening
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-mdb-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-dialog modal-dialog-scrollable">
                      <p>
                        Teeth Whitening Stains and dicoloration of teeth is
                        usually as a result of our diet, excess fluoride in
                        water we consume or medications e.g tetracycline on the
                        cause and severity, whitening can be achieved through
                        direct and indirect restorations and bleaching with
                        state of the art materials
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card">
            <img src={Diagnosis} class="card-img-top h-100" alt="Diagnosis" />
            <div class="card-body">
              <h5 class="card-title text-center">Diagnosis</h5>
              <div class="d-flex justify-content-center">
                <button
                  class="btn btn-primary"
                  type="button"
                  className="btn btn-primary"
                  data-mdb-toggle="modal"
                  data-mdb-target="#Diagnosis"
                >
                  Learn More
                </button>
              </div>

              <div
                className="modal fade"
                id="Diagnosis"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="Diagnosis">
                        Diagnosis
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-mdb-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-dialog modal-dialog-scrollable">
                      <p>
                        It's very important to visit a dentist twice every year
                        during whicha dental check-up is done and any issue
                        identified are treated.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card">
            <img
              src={Orthodontics}
              class="card-img-top h-100"
              alt="Orthodontics"
            />
            <div class="card-body">
              <h5 class="card-title text-center">Orthodontics</h5>
              <div class="d-flex justify-content-center">
                <button
                  class="btn btn-primary"
                  type="button"
                  className="btn btn-primary"
                  data-mdb-toggle="modal"
                  data-mdb-target="#Orthodontics"
                >
                  Learn More
                </button>
              </div>

              <div
                className="modal fade"
                id="Orthodontics"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="Orthodontics">
                        Orthodontics
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-mdb-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-dialog modal-dialog-scrollable">
                      <p>
                        Involves diagnosing, preventing and correcting teeth and
                        jaws which are not aligned properly. It is achieved
                        using braces, clear aligners. A diagnosis is made after
                        a thorough dental and medical history, analyzing study
                        models, special x rays and photographs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card">
            <img
              src={PediatricDentistry}
              class="card-img-top h-100"
              alt="PediatricDentistry"
            />
            <div class="card-body">
              <h5 class="card-title text-center">Pediatric Dentistry</h5>
              <div class="d-flex justify-content-center">
                <button
                  class="btn btn-primary"
                  type="button"
                  className="btn btn-primary"
                  data-mdb-toggle="modal"
                  data-mdb-target="#PediatricDentistry"
                >
                  Learn More
                </button>
              </div>

              <div
                className="modal fade"
                id="PediatricDentistry"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="PediatricDentistry">
                        Pediatric Dentistry
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-mdb-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-dialog modal-dialog-scrollable">
                      <p>
                        The earlier children are taught how to take care of
                        their oral health the more unlikely they will depart
                        from it. Diet counselling is emphasized here more. Also
                        premature loss of teeth which is one of the main causes
                        of teeth misalignment is addressed. Close monitoring is
                        provided as the child transition from milk teeth to
                        permanent ones.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="card">
            <img
              src={Radiographs}
              class="card-img-top h-100"
              alt="Radiographs"
            />
            <div class="card-body">
              <h5 class="card-title text-center">Radiographs</h5>
              <div class="d-flex justify-content-center">
                <button
                  class="btn btn-primary"
                  type="button"
                  className="btn btn-primary"
                  data-mdb-toggle="modal"
                  data-mdb-target="#Radiographs"
                >
                  Learn More
                </button>
              </div>

              <div
                className="modal fade"
                id="Radiographs"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="Radiographs">
                        Radiographs
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-mdb-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-dialog modal-dialog-scrollable">
                      <p>
                        Dental radiographs are vital tools in aiding with coming
                        up with proper diagnosis for correct treatment.
                        Radiographs helps assess extent of teeth decay, dental
                        injuries, structures supporting the teeth. We have state
                        of the art digital sensor for instant digital images
                        with a lead apron for your protection.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card">
            <img
              src={TeethFilling}
              class="card-img-top h-100"
              alt="TeethFilling"
            />
            <div class="card-body">
              <h5 class="card-title text-center">Teeth Filling</h5>
              <div class="d-flex justify-content-center">
                <button
                  class="btn btn-primary"
                  type="button"
                  className="btn btn-primary"
                  data-mdb-toggle="modal"
                  data-mdb-target="#TeethFilling"
                >
                  Learn More
                </button>
              </div>

              <div
                className="modal fade"
                id="TeethFilling"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="TeethFilling">
                        Teeth Filling
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-mdb-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-dialog modal-dialog-scrollable">
                      <p>
                        This concerns conservation of teeth in the mouth through
                        direct or indirect restoration of individual teeth. The
                        defect on the teeth is usually caused by dental
                        caries(decay), trauma. High quality aesthetics materials
                        are used to restore the teeth to function and also
                        restore aesthetics.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="card">
            <img src={RootCanal} class="card-img-top h-100" alt="RootCanal" />
            <div class="card-body">
              <h5 class="card-title text-center">Root Canal</h5>
              <div class="d-flex justify-content-center">
                <button
                  class="btn btn-primary"
                  type="button"
                  className="btn btn-primary"
                  data-mdb-toggle="modal"
                  data-mdb-target="#RootCanal"
                >
                  Learn More
                </button>
              </div>

              <div
                className="modal fade"
                id="RootCanal"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="RootCanal">
                        Root Canal
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-mdb-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-dialog modal-dialog-scrollable">
                      <p>
                        This treatment helps you maintain your natural smile,
                        continue eating the foods you love and limits the need
                        for ongoing dental work. With proper care, most teeth
                        that have had root canal treatment can last a lifetime.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card">
            <img
              src={TeethCleaning}
              class="card-img-top h-100"
              alt="TeethCleaning"
            />
            <div class="card-body">
              <h5 class="card-title text-center">Teeth Cleaning</h5>
              <div class="d-flex justify-content-center">
                <button
                  class="btn btn-primary"
                  type="button"
                  className="btn btn-primary"
                  data-mdb-toggle="modal"
                  data-mdb-target="#TeethCleaning"
                >
                  Learn More
                </button>
              </div>

              <div
                className="modal fade"
                id="TeethCleaning"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="TeethCleaning">
                        Teeth Cleaning
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-mdb-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-dialog modal-dialog-scrollable">
                      <p>
                        In some quarters, this is also called gum treatment. It
                        is very important to have your teeth cleaned atleast
                        once a year during which tartar and plaque are removed
                        and the teeth polished to restore your smile. This
                        ensures healthy gums.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="card">
            <img src={Implants} class="card-img-top" alt="Implants" />
            <div class="card-body">
              <h5 class="card-title text-center">Implants</h5>
              <div class="d-flex justify-content-center">
                <button
                  class="btn btn-primary"
                  type="button"
                  className="btn btn-primary"
                  data-mdb-toggle="modal"
                  data-mdb-target="#Implants"
                >
                  Learn More
                </button>
              </div>

              <div
                className="modal fade"
                id="Implants"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="Implants">
                        Implants
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-mdb-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-dialog modal-dialog-scrollable">
                      <p>
                        Dental implant is a component that is placed into jaw
                        bone beneath the gum to allow placement of dental
                        prothesis like a crown, bridge. Currently this is the
                        most modern method worldwide of replacing missing
                        tooth/teeth. We were not left behind!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card">
            <img
              src={ToothExtraction}
              class="card-img-top"
              alt="ToothExtraction"
            />
            <div class="card-body">
              <h5 class="card-title text-center">Tooth Extraction</h5>
              <div class="d-flex justify-content-center">
                <button
                  class="btn btn-primary"
                  type="button"
                  className="btn btn-primary"
                  data-mdb-toggle="modal"
                  data-mdb-target="#ToothExtraction"
                >
                  Learn More
                </button>
              </div>

              <div
                className="modal fade"
                id="ToothExtraction"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="ToothExtraction">
                        Teeth Extraction
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-mdb-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-dialog modal-dialog-scrollable">
                      <p>
                        We do dental extractions for both adults and children as
                        atraumatic as possible. Disimpaction of wisdom teeth and
                        also other commonly impacted teeth like canines are also
                        performed at our clinic. We manage dental infections
                        like abscess, dry socket and also perform minor oral
                        surgeries.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
