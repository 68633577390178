import React from "react";
import { Link } from "react-router-dom";

export default function Navbar() {
  return (
    <div class="container-fluid fixed-top">
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
          <Link class="navbar-brand fw-bold fs-2" to="/">
            Yaya Dental Clinic
          </Link>
        </div>
      </nav>
    </div>
  );
}
