import React from "react";

export default function Footer() {
  return (
    <>
      <footer
        class="text-center text-lg-start text-white"
        style={{ backgroundColor: "#0a4275" }}
      >
        <div class="container p-4">
          <div class="row">
            <section class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom mb-5">
              <div class="me-5 d-none d-lg-block fs-3">
                <span>
                  Follow us for the latest updates, oral
                  health tips, special offers, and more.
                </span>
              </div>
              <div>
                <a
                  class="btn text-white btn-floating m-1 me-4"
                  style={{ backgroundColor: "#3b5998" }}
                  href="https://web.facebook.com/yayadental"
                  role="button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i class="fab fa-facebook-f"></i>
                </a>

                <a
                  class="btn text-white btn-floating m-1"
                  style={{ backgroundColor: "#0082ca" }}
                  href="https://www.linkedin.com/company/yaya-dental-clinic/"
                  role="button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i class="fab fa-linkedin-in"></i>
                </a>
              </div>
            </section>

            <div class="col-lg-6 col-md-12 mb-4 mb-md-0">
              <h5 class="text-uppercase">About our Practice</h5>

              <p>
                Discover a dental clinic that prioritizes your oral health and
                well-being. Our dedicated team of experienced professionals is
                committed to providing personalized care and exceptional
                service. From routine cleanings to advanced treatments, we offer
                a comprehensive range of dental services to help you achieve a
                healthy and beautiful smile. Experience the difference of our
                compassionate approach, state-of-the-art facilities, and a
                welcoming atmosphere. Join our dental family today and take the
                first step towards optimal dental health.
              </p>
              <p>Don't wait, start your journey towards a radiant smile now!</p>
            </div>

            <div class="col-lg-6 col-md-12 mb-4 mb-md-0">
              <h5 class="text-uppercase">
                Transform your smile today! Schedule your appointment for a
                brighter, healthier, and confident you.
              </h5>

              <p>
                <i class="fa-solid fa-location-dot"></i> At Yaya Center, Nairobi
                3rd Floor, Suite Number 8
              </p>
              <p>
                <i class="fa-solid fa-clock"></i> Mon - Sat : 8am - 6pm
              </p>
              <p>
                <i class="fa-solid fa-clock"></i> Sundays : On Special
                Arrangements
              </p>
              <p>
                <i class="fa-solid fa-phone"></i> 0202035183 or +254723934728 or
                +254728229631
              </p>
              <p>
                <i class="fa-solid fa-envelope"></i> yayadentalclinic@gmail.com
              </p>
            </div>
          </div>
        </div>

        <div class="text-center p-3 text-white bg-dark">
          &copy; {new Date().getFullYear()} Copyright
        </div>
      </footer>
    </>
  );
}
