import React from "react";
import Aketch from "./images/ourTeam/Aketch.jpeg";
import Linet from "./images/ourTeam/Linet.jpeg";
import Liyosi from "./images/ourTeam/Liyosi.jpeg";
import Nita from "./images/ourTeam/Nita.jpeg";

export default function OurTeam() {
  return (
    <div className="container mt-5 pt-5 mb-3">
      <h1 className="text-center">Our Team</h1>
      <h3 className="mb-4 text-dark text-center">
        Meet our exceptional dental clinic team, dedicated to providing you with
        the best oral care experience. With a passion for dentistry and a
        commitment to your well-being, our skilled professionals are here to
        create healthy smiles that last a lifetime. From preventive treatments
        to restorative procedures, we offer a wide range of services tailored to
        your unique needs. With a warm and welcoming atmosphere, we strive to
        make your dental visit comfortable and stress-free. Trust in our
        expertise and let us take care of your dental health.
      </h3>

      <div className="row row-cols-1 row-cols-md-2 g-4">
        <div className="col">
          <div className="card h-100">
            <img src={Aketch} className="card-img-top h-100" alt="Aketch" />
            <div className="card-body">
              <div className="text-center">
                <h5 className="card-title text-center">Dr. Collins Aketch</h5>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-mdb-toggle="modal"
                  data-mdb-target="#Aketch"
                >
                  Discover More
                </button>
              </div>

              <div
                className="modal fade"
                id="Aketch"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="Aketch">
                        Dr. Collins Aketch
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-mdb-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-dialog modal-dialog-scrollable">
                      <p>
                        He is a dentist with years of experience both in private
                        practice and public practice, having worked in renowned
                        institutions such as The Aga khan University hospital
                        Nairobi, Metropolitan hospital and Kenyatta National
                        hospital just to mention but a few. He has special
                        interests in aesthetic and restorative dentistry and
                        attends both local and international courses to improve
                        his skill.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card h-100">
            <img src={Linet} className="card-img-top h-100" alt="Linet" />
            <div className="card-body">
              <div className="text-center">
                <h5 className="card-title text-center">Linet</h5>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-mdb-toggle="modal"
                  data-mdb-target="#Linet"
                >
                  Discover More
                </button>
              </div>

              <div
                className="modal fade"
                id="Linet"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="Linet">
                        Linet
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-mdb-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-dialog modal-dialog-scrollable">
                      <p>
                        She is a dental assistant with 18 years of experience.
                        She is ever happy and grateful to have a job that she
                        fully enjoys. Besides, her life is extremely fulfilled
                        by this career. Working at yaya dental clinic is always
                        a wonderful experience and she has gained exposure and
                        maximum experience. She loves the excellence of team I
                        work with.
                      </p>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card h-100">
            <img src={Liyosi} className="card-img-top h-100" alt="Liyosi" />
            <div className="card-body">
              <div className="text-center">
                <h5 className="card-title text-center">Dr. Collins Liyosi</h5>
                <p>
                  <a href="https://www.linkedin.com/in/collinsliyosi/" target="_blank" rel="noopener noreferrer">
                    <i class="fa-brands fa-linkedin fa-2x"></i>
                  </a>
                </p>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-mdb-toggle="modal"
                  data-mdb-target="#Liyosi"
                >
                  Discover More
                </button>
              </div>

              <div
                className="modal fade"
                id="Liyosi"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="Liyosi">
                        Dr. Collins Liyosi
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-mdb-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-dialog modal-dialog-scrollable">
                      <p>
                        He is a dental practitioner who is passionate with
                        dentistry and a team player. He is biased towards
                        pediatric dentistry with several years of experience in
                        both private and public sectors managing a wide range of
                        dental issues from jaw fractures to dental emergencies.
                        He strives to improve his skills through various hands
                        on courses organized locally and internationally for the
                        satisfaction of our clients.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col">
          <div className="card h-100">
            <img src={Nita} className="card-img-top h-100" alt="Nita" />
            <div className="card-body">
              <h5 className="card-title text-center">Nita</h5>

              <div className="text-center">
                <button
                  type="button"
                  className="btn btn-primary align-items-center"
                  data-mdb-toggle="modal"
                  data-mdb-target="#Nita"
                >
                  Discover More
                </button>
              </div>

              <div
                className="modal fade"
                id="Nita"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="Nita">
                        Nita
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-mdb-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-dialog modal-dialog-scrollable">
                      <p>
                        She is an ever jovial and friendly dental assistant who
                        assists the dentists in all dental procedures and
                        perform clerical duties related to the dental program.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
